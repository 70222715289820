<template>
  <div>
    <div class="percent-bar-container" v-if="PercentualSucesso && errorPercentage">
      <el-tooltip :content="PercentualSucesso + '%'" placement="top">
        <div class="percent-bar success" :style="{ width: PercentualSucesso + '%' }">
          <span v-show="PercentualSucesso >= 20">{{ PercentualSucesso }}%</span>
        </div>
      </el-tooltip>
      <el-tooltip :content="PercentualErro + '%'" placement="top">
        <div class="percent-bar error" :style="{ width: PercentualErro + '%' }">
          <span v-show="PercentualErro >= 20">{{ PercentualErro }}%</span>
        </div>
      </el-tooltip>
    </div>
    <div v-else>
      <p class="info-no-data">Sem dados</p>
    </div>
  </div>
</template>


<script>

export default {
  name: "GraficoAcerto",
  props: ['successPercentage', 'errorPercentage'],
  data() {
    return {};
  },
  created() {
  },
  mounted() {
  },
  components: {},
  watch: {},
  methods: {},
  computed: {
    PercentualSucesso() {
      return parseFloat(this.successPercentage.toFixed(1));
    },
    PercentualErro() {
      return parseFloat(this.errorPercentage.toFixed(1));
    }
  }
}
</script>

<style scoped>
.percent-bar-container {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #f0f0f0;
}

.percent-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
}

.success {
  background-color: #28a745;
}

.error {
  background-color: #dc3545;
}

.info-no-data {
  font-size: 10px;
  top: 10px;
  position: relative;
  color: #999;
}
</style>
