import PostService from './PostService'
import UtilService from './UtilService'

export default class ParceiroService {
    constructor(source) {
        this.source = source
        this.util = new UtilService(source)
        this.postService = new PostService(this.source, true)
    }

    Pegar(callback, callbackErro, clear) {
        let result = null;
        result = clear ? null : this.PegarParceiroAtual()
        if (!result) {
            this.postService.Post('parceiro/Pegar', null, (res) => {
                this.SetParceiroAtual(res.Data[0])
                callback(res);
            }, callbackErro, true)
        } else {
            callback({
                HasResult: true,
                success: true,
                Data: [result],
            })
        }
    }

    PegarParceiro(id, callback, callbackErro) {
        this.postService.Post('parceiro/PegarParceiro?id=' + id, null, callback, callbackErro, true)
    }

    Listar(filtro, callback, callbackErro, hideLoading) {
        this.postService.Post('parceiro/Listar', filtro, callback, callbackErro, hideLoading)
    }

    ListarPlanos(callback, callbackErro) {
        this.postService.Post('parceiro/ListarPlanos', null, callback, callbackErro, true)
    }

    GravarImagens(formData, callback, callbackErro) {
        this.postService.PostFormData('parceiro/GravarImagens', formData, callback, callbackErro, true)
    }

    Gravar(parceiro, callback, callbackErro) {
        this.postService.Post('parceiro/Gravar', parceiro, callback, callbackErro, true)
    }

    RemoverAgencia(idAgencia, nomeAgencia, callback, callbackErro) {
        this.postService.Post('parceiro/RemoverAgencia?idParceiro=' + idAgencia + '&confirmaNome=' + nomeAgencia, null, callback, callbackErro, true)
    }

    ListarFaturas(callback, callbackErro) {
        this.postService.Post('parceiro/ListarFaturas', null, callback, callbackErro, true)
    }

    ListarTemas(callback, callbackErro) {
        this.postService.Post('parceiro/ListarTemas', null, callback, callbackErro, true)
    }

    GravarTema(obj, callback, callbackErro) {
        this.postService.Post('parceiro/GravarTema', obj, callback, callbackErro, true)
    }

    CustomDomainCheck(url, callback, callbackErro) {
        this.postService.Post('parceiro/CustomDomainCheck?domainName=' + url, null, callback, callbackErro, true)
    }

    CriarDominio(hostName, callback, callbackErro) {
        this.postService.Post('parceiro/CriarDominio?hostName=' + hostName, null, callback, callbackErro, true)
    }

    GravarScript(script, callback, callbackErro) {
        this.postService.Post('parceiro/GravarScript', script, callback, callbackErro, true)
    }

    PegarScript(callback, callbackErro) {
        this.postService.Post('parceiro/PegarScript', null, callback, callbackErro, true)
    }

    PegarItemConfiguracao(filtro, callback, callbackErro) {
        this.postService.Post('parceiro/PegarItemConfiguracao', filtro, callback, callbackErro, true)
    }

    GravarItemConfiguracao(filtro, callback, callbackErro) {
        this.postService.Post('parceiro/GravarItemConfiguracao', filtro, callback, callbackErro, true)
    }

    GravarPerfil(perfil, callback, callbackErro) {
        this.postService.Post('parceiro/GravarPerfil', perfil, callback, callbackErro, true)
    }

    PegarPerfil(callback, callbackErro) {
        this.postService.Post('parceiro/PegarPerfil', null, callback, callbackErro, true)
    }

    ListarPermissoes(callback, callbackErro) {
        this.postService.Post('parceiro/ListarPermissoes', null, callback, callbackErro, true);
    }

    ResetarDataCriacaoAgencia(callback, callbackErro) {
        this.postService.Post('parceiro/ResetarDataCriacaoAgencia', null, callback, callbackErro, true);
    }

    PegarNomePlano(idPlano) {
        switch (idPlano) {
            case 3:
                return 'Grátis';
            case 1:
                return 'Básico';
            case 0:
                return 'Profissional';
            case 4:
                return 'Avançado';
            case 2:
                return 'API Moblix'
            default:
                return '--'
        }
    }

    _GetAppMain() {
        let source = this.source
        while (source.name !== 'App' && source.$parent !== undefined)
            source = source.$parent
        return source;
    }

    PegarParceiroAtual() {
        return this._GetAppMain().LogadoComo ?? this._GetAppMain().ParceiroAtual;
    }

    SetParceiroAtual(p) {
        this._GetAppMain().ParceiroAtual = p;
    }

    SetLogadoComo(p) {
        this._GetAppMain().LogadoComo = p;
    }


    PegarUrlParceiro(parceiro) {
        let url = '';

        if (parceiro && parceiro.Configuracao && parceiro.ParceiroDominio && parceiro.ParceiroDominio.length) {

            if (parceiro.Configuracao.UrlTemplateExterno) {
                url = parceiro.Configuracao.UrlTemplateExterno
            } else {
                const parceiroDominioPrincipal = parceiro.ParceiroDominio.find(x => x.Principal === true);
                if (parceiroDominioPrincipal) {
                    url = parceiroDominioPrincipal.Url;
                } else {
                    url = parceiro.ParceiroDominio[0].Url;
                }
            }

            const isProd = process.env.NODE_ENV === "production"
            const protocolo = isProd && parceiro.Configuracao.PossuiCertificadoHttps ? 'https' : 'http';
            url = `${protocolo}://${url}/`;
        }
        return url;
    }

    ComprimirQueryStringJson(obj, callback, callbackErro) {
        this.postService.Post('parceiro/ComprimirQueryStringJson', {Json: JSON.stringify(obj)}, callback, callbackErro, true);
    }
}
