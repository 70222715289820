<template>
  <el-row>
    <el-row :gutter="20" class="margin-top-25">
      <el-form>
        <el-row v-if="data">
          <el-col :span="8">
            <el-form-item label="Minutos bloqueio">
              <el-input-number v-model="data.MinutosBloqueio" size="mini"/>
              <p class="p-info-desc">Tempo em minutos que um proxy ficará bloqueado quando não atender a taxa de acerto</p>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Minutos listagem">
              <el-input-number v-model="data.MinutosListagem" size="mini"/>
              <p class="p-info-desc">Tempo de listagem no banco para montar a taxa de acerto</p>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Percentual erro máximo">
              <el-input-number v-model="data.PercentualErroMaximo" size="mini"/>
              <p class="p-info-desc">Percentual máximo de erro para bloquear</p>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
  </el-row>
</template>


<script>
import ProxyManagerService from "@/services/ProxyManagerService";

export default {
  name: "ProxyManagerConfig",
  props: ['idCia'],
  data() {
    return {
      data: null
    };
  },
  created() {
    this.proxyManagerService = new ProxyManagerService(this)
  },
  mounted() {
    this.Listar()
  },
  watch: {
    idCia() {
      this.Listar()
    }
  },
  methods: {
    Listar() {
      this.proxyManagerService.ListarProxyConfig(this.idCia, this.callback, this.error);
    },
    callback(res) {
      if(res.HasResult) {
        this.data = res.Data[0]
      } else {
        this.newData()
      }
    },
    error(err) {
      console.error(err);
      this.$message.error('Erro ao gravar configuração');
    },
    newData() {
      this.data = {
        MinutosBloqueio: 0,
        MinutosListagem: 0,
        PercentualErroMaximo: 0,
        IdCia: this.idCia
      }
    },
    Gravar() {
      this.proxyManagerService.GravarProxyConfig(this.data, this.callback, this.error);
    }
  },
  computed: {}
};
</script>

<style scoped>
.p-info-desc {
  font-size: 12px;
  color: #909399;
  margin-top: 5px;
  margin-bottom: 0px !important;
  line-height: 20px;
}
</style>
