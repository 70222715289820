<template>
  <card :title="selected" :hide-header="true">
    <el-row class="margin-top-15">
      <el-col :span="12">
        <el-radio-group v-model="selected" size="mini" v-if="isEdicaoProxyPorCia">
          <el-radio-button v-for="(status, index) in ListaCompanhias" :label="status.desc" v-bind:key="index"
            v-bind:disabled="status.disabled">
            <img :src="status.path" height="15" class="img-cia" v-bind:class="{ 'preto-branco': status.disabled }" />
          </el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="12" class="text-right" v-if="!isProxiesPath">
        <label class="lb-refresh">Autorefresh {{ refresh ? 'On' : 'Off' }}</label>
        <el-switch v-model="refresh" @change="changeRefresh" />
      </el-col>
    </el-row>
    <el-row>
      <table-proxies ref="tableProxies" :id-cia="idCiaSelecionada" />
    </el-row>
  </card>
</template>

<script>
import Card from "@/components/Shared/Cards/Card.vue";
import Gol from "@/assets/img/Logos/gol.png";
import Azul from "@/assets/img/Logos/Azul.png";
import TableProxies from "@/components/Proxy/TableProxiesCia.vue";

export default {
  name: "ProxyManager",
  components: { TableProxies, Card },
  data() {
    return {
      refresh: true,
      selected: 'Gol',
      ListaCompanhias: [{
        id: 2,
        desc: "Gol",
        path: Gol,
      },
      {
        id: 3,
        desc: "Azul",
        path: Azul,
      }],
    };
  },
  created() {
  },
  mounted() {
    this.buildBtnAction();
    this.changeRefresh();
  },
  watch: {
    $route() {
      this.buildBtnAction();
      this.$refs.tableProxies.rebuild();
      if (this.refresh)
        this.$refs.tableProxies.buildListInterval();
    }
  },
  methods: {
    changeRefresh() {
      if (this.refresh)
        this.$refs.tableProxies.buildListInterval();
      else
        this.$refs.tableProxies.destroyListInterval();
    },
    buildBtnAction() {
      if (this.isEdicaoProxyPorCia)
        this.$UtilMbx.SetDropdownHeaderPage(this, [{
          text: 'Salvar',
          icon: 'el-icon-check',
          fn: this.Salvar
        }, {
          text: 'Proxies',
          icon: 'el-icon-edit',
          fn: () => this.$router.push("/proxies")
        }])
      else
        this.$UtilMbx.SetDropdownHeaderPage(this, [{
          text: 'Salvar',
          icon: 'el-icon-check',
          fn: this.Salvar
        }, {
          text: 'Novo',
          icon: 'el-icon-plus',
          fn: this.NovoProxy
        }])
    },
    NovoProxy() {
      let newProxy = {
        Id: 0,
        Config: 'newProxy_' + Math.floor(10000000 + Math.random() * 90000000),
        IsSubnet: false,
        ProxyCia: []
      };
      this.$refs.tableProxies.Proxies.push(newProxy);
      this.$refs.tableProxies.Selected = newProxy;
    },
    Salvar() {
      this.$refs.tableProxies.Salvar();
    }
  },
  computed: {
    idCiaSelecionada() {
      return this.ListaCompanhias.find(x => x.desc === this.selected).id;
    },
    isEdicaoProxyPorCia() {
      return this.$route.meta.isProxyCiaEdit ?? false;
    },
    isProxiesPath() {
      return this.$route.path === '/proxies';
    }
  }
};
</script>

<style scoped>
.lb-refresh {
  font-size: 12px;
  margin-right: 10px;
}

.lb-filter {
  font-size: 12px;
  margin-left: 20px;
  margin-right: 10px;
}
</style>
