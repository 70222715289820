<template>
  <div id="divContainerHelp" class="container-help">
    <transition enter-active-class="animated zoomIn" leave-active-class="animated zoomOut"
      @after-enter="showIcon = true">
      <el-row class="content-help" v-show="showContainer">
        <el-col>
          <transition enter-active-class="animated zoomIn" @after-enter="builded = true">
            <el-image v-show="showIcon" class="img-help margin-bottom-15" :src="iconUrl" @load="calcSizeMenu" />
          </transition>
        </el-col>
        <el-col v-if="builded">
          <vue-typed-js ref="vueTyped" :strings="frases" :typeSpeed="50" :backSpeed="20" @onComplete="typedDone()">
            <p class="full-width text-center"><span class="typing"></span></p>
          </vue-typed-js>
          <transition enter-active-class="animated fadeInUp">
            <p v-show="showPeriodoTesteGratis" class="spn-free-test">Você tem {{ (14 - parceiro.TempoCadastro) }} dias
              de teste grátis!</p>
          </transition>
          <transition enter-active-class="animated bounceIn"
            @after-enter="showPeriodoTesteGratis = parceiro.PeriodoAvaliacao">
            <el-button @click="goPage" v-show="showButton" class="margin-top-20">🚀 Upgrade</el-button>
          </transition>
        </el-col>
      </el-row>
    </transition>
  </div>
</template>

<script>
import IconSaudacao from '@/assets/frases/IconsSaudacao.json'
import AgradecimentoAquisicao from '@/assets/frases/AgradecimentoAquisicao.json'
import Saudacoes from '@/assets/frases/Saudacoes.json'
import Motivacional from '@/assets/frases/Motivacional.json'
import AdquirirProduto from '@/assets/frases/AdquirirProduto.json'
import UtilService from "@/services/UtilService"
import ClienteService from "@/services/ClienteService";
import ParceiroService from "@/services/ParceiroService";

export default {
  name: "HelpBox",
  data() {
    return {
      iconUrl: '',
      frases: [],
      builded: false,
      showContainer: false,
      showIcon: false,
      showButton: false,
      userName: '',
      showPeriodoTesteGratis: false
    }
  },
  created() {
    this.cliService = new ClienteService(this)
    this.parceiroService = new ParceiroService(this)
    this.util = new UtilService(this)
    this.parceiro = this.parceiroService.PegarParceiroAtual();
    this.cliService.PegarUsuarioAtual(this.CallbackUsuarioAtual)
    this.iconUrl = this.GetRandomItemArray(IconSaudacao)
  },
  mounted() {
    this.calcSizeMenu();
    window.addEventListener("resize", this.calcSizeMenu);
    this.showContainer = true;
  },
  methods: {
    CallbackUsuarioAtual(res) {
      let user = res.Data[0];
      this.userName = user ? user.Nome.split(' ')[0] : 'Usuário';
      let frase = `${this.GetRandomItemArray(Saudacoes)} ${this.GetRandomItemArray(Motivacional)}`
      this.frases.push(frase)
      if (this.ProximoPlano()) {
        this.frases.push(this.GetRandomItemArray(AdquirirProduto))
      }
    },
    GetRandomItemArray(array) {
      let randomIndex = this.util.RandomInt(0, array.length - 1)
      let retorno = array[randomIndex];
      retorno = retorno.replace('{user}', this.userName)
      retorno = retorno.replace('{plano}', this.ProximoPlano())
      retorno = retorno.replace('{bom_dia_tarde_noite}', this.generateGreetings())
      return retorno;
    },
    calcSizeMenu() {
      let hHelpContainer = document.getElementById('divContainerHelp').offsetHeight,
        hSideMenu = document.getElementById('sidMenuBarra').offsetHeight,
        hMenuItens = hSideMenu - hHelpContainer

      const element = document.getElementById("elMenuLateral");
      element.style.height = `${hMenuItens}px`;
    },
    ProximoPlano() {
      let basico = [1, 6],
        profissional = [0, 7];
      // avancado = [8, 4];
      if (basico.includes(this.parceiro.IdPlano) || this.parceiro.PeriodoAvaliacao)
        return 'Profissional';
      else if (profissional.includes(this.parceiro.IdPlano))
        return 'Avançado';
      else if (this.parceiro.IdPlano === 3)
        return 'Básico';

      return null;
    },
    generateGreetings() {
      var currentHour = this.$moment().format("HH");
      if (currentHour >= 3 && currentHour < 5) {
        return "Madrugando hein";
      } else if (currentHour >= 5 && currentHour < 12) {
        return "Bom dia";
      } else if (currentHour >= 12 && currentHour < 17) {
        return "Boa tarde";
      } else if ((currentHour >= 17 && currentHour < 23) ||
        (currentHour >= 0 && currentHour < 3)) {
        return "Boa noite";
      }
    },
    typedDone() {
      if (this.ProximoPlano())
        this.showButton = true;
    },
    goPage() {
      window.location.href = '#/MeuPlano'
    },
    MigrouPlano() {
      this.showPeriodoTesteGratis = false;
      this.$refs.vueTyped.typedObj.strings = [];
      let frase = this.GetRandomItemArray(AgradecimentoAquisicao)
      this.$refs.vueTyped.typedObj.strings.push(frase)
      this.$refs.vueTyped.typedObj.reset()
    }
  }
}
</script>

<style scoped>
.container-help {
  padding: 20px;
  background: var(--primary-color);
  ;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.content-help {
  padding: 20px;
  background-color: #353942;
  border-radius: 6px;
  color: #ffffffbd;
  text-align: center;
  min-height: 170px;
  font-size: 14px;
}

.content-help img {
  margin-bottom: 30px;
}

.content-help label {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
}

.img-help {
  height: 32px;
  width: 32px;
}

.spn-free-test {
  color: var(--danger);
  margin-bottom: 0;
}
</style>