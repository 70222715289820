<template>
  <div>
    <el-badge v-if="unreadCount > 0" class="notification-badge">
      <span class="icon-bell" @click="toggleNotificationPanel">
        <icon name="bell" scale="1"></icon>
      </span>
      <span class="blink" v-show="unreadCount > 0">{{ unreadCount }}</span>
    </el-badge>
    <span v-else class="icon-bell" @click="toggleNotificationPanel">
      <icon name="bell" scale="1"></icon>
    </span>
    <el-drawer :visible.sync="showNotificationPanel" direction="rtl" :size="410" class="notification-panel">
      <template slot="title">
        <div class="custom-drawer-header">
          Notificações
        </div>
      </template>
      <div class="el-drawer__container notification-panel-container">
        <p class="left-aligned">
          <el-tag v-if="unreadCount > 0" type="danger" effect="dark" strong>{{ unreadCount }}
            notificações</el-tag>
          <el-button v-if="unreadCount > 0" size="mini" type="text" class="ml-2" style="color: var(--el-color-success)"
            @click="markAllAsRead">Marcar todas como lido</el-button>
        </p>
        <div v-if="unreadCount === 0" class="no-notifications">
          <img src="https://cdn-icons-png.flaticon.com/128/3264/3264806.png" alt="Sem notificações" />
          <h5>Pode descansar, não existe nenhuma notificação</h5>
        </div>
        <div v-else v-for="(notification, index) in paginatedNotifications" :key="index" class="notification-item">
          <el-card shadow="hover" class="card-container">
            <div class="card-top" @click="showImagePreview(notification.image)">
              <el-avatar shape="square"
                style="height: 50px; width: 80px; line-height: 60px; display: flex; justify-content: center; align-items: center;"
                class="card-left center-image" :src="notification.image"></el-avatar>
              <div class="buttons-left">
                <span class="mx-1" size="small" tag="i">
                  <el-icon class="calendar-icon" name="date"></el-icon>
                  {{ formatDate(notification.date) }}
                </span>
                <span class="time-since">
                  {{ timeSince(notification.date) }}
                </span>
              </div>
            </div>
            <div class="card-right">
              <p>{{ notification.title }}</p>
            </div>
            <div class="card-description">
              <p>{{ notification.description }}</p>
            </div>
            <div class="card-bottom">
              <div class="buttons-right">
                <el-button size="mini" v-if="notification.webUrl" icon="el-icon-link" type="default" plain
                  @click="learnMore(notification.id)"></el-button>
                <el-button size="mini" icon="el-icon-check" type="default" @click="markAsRead(index)"></el-button>
              </div>
            </div>
          </el-card>
        </div>
        <el-dialog :visible.sync="imagePreviewVisible" width="50%" :before-close="closeImagePreview">
          <img :src="currentPreviewImage" style="width: 100%;" />
        </el-dialog>
        <el-pagination v-if="unreadCount > 0" v-model="currentPage" :page-size="3" :total="unreadCount"
          layout="total, prev, pager, next" @current-change="handlePageChange" />
      </div>
    </el-drawer>
  </div>
</template>


<script>
import Vue from 'vue';
import OneSignalVue from 'onesignal-vue';
import axios from 'axios';
import '@/assets/geral.css';
import ParceiroService from "@/services/ParceiroService";
import notificationSound from '@/assets/sound/notification_sound.mp3';


Vue.use(OneSignalVue);

export default {
  data() {
    return {
      showNotificationPanel: false,
      firstLoad: true,
      currentPage: 1,
      unreadCount: 0,
      parceiro: {},
      notifications: [],
      imagePreviewVisible: false,
      currentPreviewImage: '',
      hasNewNotifications: false,
      idParceiroAtual: null,
      pollingInterval: null,
    };
  },
  created() {
    this.parceiroService = new ParceiroService(this);
    this.fetchNotifications();
    this.listenForNewNotifications();
    this.startPolling();
    this.$on('OneSignal:subscriptionChange', (isSubscribed) => {
      if (isSubscribed) {
        this.fetchNotifications();
      } else {
        this.notifications = [];
        this.unreadCount = 0;
      }
    });
    this.idParceiroAtual = this.parceiroService.PegarParceiroAtual().Id
  },
  computed: {
    paginatedNotifications() {
      const start = (this.currentPage - 1) * 3;
      const end = start + 3;
      return this.notifications
        .filter((notification) => !notification.read)
        .slice(start, end);
    },
    drawerTitle() {
      return `Minhas notificações (${this.unreadCount} não lidas)`;
    },

    headerStyle() {
      const backgroundColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--el-drawer__container-notification-panel-container-bg');
      return `background-color: ${backgroundColor};`;
    },
  },
  methods: {
    checkForNewNotifications(newMessages) {
      const unreadMessages = newMessages.filter((message) => !message.read);
      const previousUnreadCount = this.unreadCount;

      // Atualize a contagem de mensagens não lidas antes de verificar se há novas notificações
      this.unreadCount = this.notifications.filter((notification) => !notification.read).length;

      // Verifique se há novas notificações comparando a contagem de mensagens não lidas
      if (unreadMessages.length > 0 && this.unreadCount > previousUnreadCount && !this.firstLoad) {
        this.playNotificationSound();
      } else {
        this.firstLoad = false;
      }
    },
    mergeAndSortNotifications(newNotifications) {
      const nonDuplicateNotifications = newNotifications.filter(newNotification => {
        return !this.notifications.some(existingNotification => existingNotification.id === newNotification.id);
      });
      this.notifications = [...this.notifications, ...nonDuplicateNotifications];
      this.notifications.sort((a, b) => new Date(b.date) - new Date(a.date));
      this.unreadCount = this.notifications.filter((notification) => !notification.read).length;
    },
    removeEmojis(text) {
      const regex = /:\w+:/g;
      return text.replace(regex, '');
    },
    showImagePreview(image) {
      this.currentPreviewImage = image;
      this.imagePreviewVisible = true;
    },
    closeImagePreview(done) {
      done();
      this.imagePreviewVisible = false;
      this.currentPreviewImage = '';
    },
    fetchNotifications() {
      const appId = 'fa27bb30-d523-49db-babb-17ea9ce7a775';
      const apiKey = 'YzlhZWNhZDgtODUwZC00NDE1LThjYmUtODRiYTA1ZGIxNTFh';
      const playerId = 'ZmI5YzQxNjctNzYzOS00YzcyLWE5NTItZDUxM2MzYmNjMjhi';

      axios
        .get(`https://onesignal.com/api/v1/notifications?app_id=${appId}&player_id=${playerId}`, {
          headers: {
            Authorization: `Basic ${apiKey}`,
          },
        })
        .then((response) => {
          const readNotifications = JSON.parse(localStorage.getItem('readNotifications')) || [];

          this.notifications = response.data.notifications
            .filter((notification) => !readNotifications.includes(notification.id))

            .map((notification) => {
              const imageUrl = notification.chrome_web_image || 'https://cdn-icons-png.flaticon.com/128/3264/3264806.png';
              const webUrl = notification.url || '';

              return {
                id: notification.id,
                title: notification.headings.en,
                description: notification.contents.en,
                image: imageUrl,
                webUrl: webUrl,
                date: notification.queued_at ? new Date(notification.queued_at * 1000) : new Date(),
                read: false,
              };
            });
          this.unreadCount = this.notifications.filter((notification) => !notification.read).length;
          this.mergeAndSortNotifications(this.notifications);
        })
        .catch((error) => {
          console.error('Erro ao buscar notificações:', error);
        });
    },
    addNewNotification(newNotificationData) {
      const newNotification = {
        id: newNotificationData.id,
        title: newNotificationData.payload.title,
        description: newNotificationData.payload.body,
        image: newNotificationData.payload.chrome_web_image || 'https://cdn-icons-png.flaticon.com/128/3264/3264806.png',
        webUrl: newNotificationData.payload.url || '',
        date: new Date(),
        read: false,
      };
      this.notifications.unshift(newNotification);
      this.unreadCount++;
      console.log('Nova notificação adicionada. Tentando tocar o som...'); // Adicione esta linha
      this.playNotificationSound();
    },
    startPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
      }

      this.pollingInterval = setInterval(() => {
        this.fetchNotifications();
      }, 40000); // 2 minutos
    },

    listenForNewNotifications() {
      if (window.OneSignal) {
        window.OneSignal.push(() => {
          window.OneSignal.on('notificationDisplay', (newNotification) => {
            console.log('Nova notificação recebida. Adicionando notificação...'); // Adicione esta linha
            this.addNewNotification(newNotification);
            if (!this.firstLoad) {
              this.playNotificationSound();
              this.hasNewNotifications = true;
            } else {
              this.firstLoad = false;
            }
          });
        });
      }
    },

    learnMore(notificationId) {
      const notification = this.notifications.find((n) => n.id === notificationId);
      if (notification && notification.webUrl) {
        window.open(notification.webUrl, '_blank');
      } else {
        console.error('URL de lançamento não encontrada');
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
    playNotificationSound() {
      console.log('Tentando tocar o som da notificação...'); // Adicione esta linha
      const audio = new Audio(notificationSound);
      audio.play();
      console.log('Som da notificação tocado com sucesso.'); // Adicione esta linha
    },
    toggleNotificationPanel() {
      this.showNotificationPanel = !this.showNotificationPanel;
    },
    markAsRead(index) {
      const globalIndex = (this.currentPage - 1) * 3 + index;
      const notificationId = this.notifications[globalIndex].id;
      this.saveReadNotification(notificationId);
      this.notifications[globalIndex].read = true;
      this.notifications.splice(globalIndex, 1);
      this.unreadCount = this.notifications.filter((notification) => !notification.read).length;
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    timeSince(date) {
      const now = new Date();
      const seconds = Math.floor((now - new Date(date)) / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (minutes < 60) {
        return `${minutes} minutos atrás`;
      } else if (hours < 24) {
        return `${hours} horas atrás`;
      } else {
        return `${days} dias atrás`;
      }
    },
    markAllAsRead() {
      for (let i = 0; i < this.notifications.length; i++) {
        this.saveReadNotification(this.notifications[i].id);
        this.notifications[i].read = true;
      }
      this.unreadCount = 0;
      this.currentPage = 1;
    },
    truncateDescription(text) {
      const splitIndex = text.indexOf("Split");
      if (splitIndex !== -1) {
        return text.slice(0, splitIndex);
      }
      return text;
    },
    saveReadNotification(notificationId) {
      let readNotifications = JSON.parse(localStorage.getItem('readNotifications')) || [];
      readNotifications.push(notificationId);
      localStorage.setItem('readNotifications', JSON.stringify(readNotifications));
    },
  },
  watch: {
    hasNewNotifications(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.fetchNotifications();
        this.hasNewNotifications = false;
      }
    },
  },

};

</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  cursor: default;
}

.card-top {
  display: flex;
}

.card-right {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  text-align: left;
  white-space: pre-line;
  word-wrap: normal;
  font-size: 0.9rem !important;
  font-weight: 500;
}

.card-description {
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow-wrap: break-word;
  word-break: break-all;
  font-size: 14px;
  white-space: pre-line;
  word-wrap: normal;
}

.card-bottom {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}

.buttons-right {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.buttons-left {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  font-size: 13px;
}

.time-since {
  display: block;
  gap: 5px;
  text-align: left;
  padding-left: 6px;
  padding-top: 5px;
}

.notification-badge {
  position: relative;
  font-size: 20px;
}

.notification-panel {
  height: 100vh;
  overflow-y: auto;
}

.notification-item {
  padding: 0px 10px;
}

.icon-bell {
  color: var(--gray);
  font-size: 20px;
}

.no-notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
}

.no-notifications img {
  width: 35%;
  height: auto;
}

.no-notifications h5 {
  margin-top: 20px;
  text-align: center;
  padding: 0 50px;
  overflow-wrap: break-word;
}

.left-aligned {
  text-align: left;
  margin-left: 10px;
  padding-top: 10px;
  cursor: default;
}

.el-drawer__container.notification-panel-container {
  background-color: #fff !important;
  cursor: default;
}

.el-drawer.rtl {
  -webkit-animation: rtl-drawer-out .3s;
  animation: rtl-drawer-out .3s;
  right: 0;
}

.el-drawer {
  cursor: default;
}

.el-tag {
  cursor: default;
}

.icon-bell:hover {
  cursor: pointer;
}

.el-button {
  cursor: pointer;
}

.calendar-icon {
  margin-right: 5px;
}

@keyframes blink {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.6;
  }

  70% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.custom-drawer-header {
  padding-left: 10px;
}

.blink {
  position: absolute;
  top: -2px;
  right: -10px;
  background-color: #f56c6c;
  color: #fff;
  border-radius: 8px !important;
  font-size: 10px !important;
  height: 16px !important;
  width: 16px !important;
  animation: blink 3.0s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h6 {
  font-size: 0.9rem !important;
}

.center-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-pagination {
  padding: 2px 13px !important;
}

.el-card {
  background-color: transparent;
}
</style>