<template>
  <div class="view-container">
    <el-card class="card-container">
      <div>
        <el-image :src="imageUrl" fit="cover" style="width: 150px"></el-image>
      </div>
      <el-steps class="margin-step" :active="stepPosition" v-if="isAfiliadoTrivelo || isAfiliadoMoblix"
                finish-status="success">
        <el-step></el-step>
        <el-step></el-step>
        <el-step></el-step>
      </el-steps>
      <el-row class="margin-top-30 margin-bottom-20" v-if="!isAfiliadoTrivelo && !isAfiliadoMoblix">
        <h4 class="subtitle">Faça sua conta grátis! 🚀</h4>
      </el-row>
      <el-form :model="agencia" :rules="getRules()" label-position="top" ref="agenciaForm">
        <div v-show="stepPosition === 0" class="form-inline">
          <el-form-item prop="Email" class="col-md-12">
            <el-input type="email" v-model="agencia.Email" placeholder="Seu e-mail" suffix-icon="el-icon-message"
                      autocomplete="email"/>
          </el-form-item>
          <el-form-item class="col-md-12 margin-top-15" prop="NomeEmpresa">
            <el-input type="text" v-model="agencia.NomeEmpresa" placeholder="Nome da sua empresa"
                      suffix-icon="el-icon-office-building" autocomplete="off"/>
          </el-form-item>
          <el-form-item prop="Senha" class="col-md-12 margin-top-15">
            <el-input type="password" v-model="agencia.Senha" placeholder="Sua senha" suffix-icon="el-icon-lock"
                      autocomplete="new-password"/>
          </el-form-item>
          <el-form-item class="col-md-12 margin-top-15">
            <el-checkbox v-model="agencia.AceitoTermos" class="checkbox-content" :value="true">Eu li e aceito os <a
                href="https://moblix.com.br/Termos" target="_blank">termos de uso</a>.
            </el-checkbox>
            <span v-show="!agencia.AceitoTermos && validationStart" class="spn-erro-termos">Você precisa ler e concordar
              com os Termos de Uso e a Política de Privacidade.</span>
          </el-form-item>
        </div>
        <div v-show="stepPosition === 1" class="form-inline-b margin-top-15">
          <el-row class="margin-bottom-20">
            <h5 class="subtitle">Informe seus telefone e endereço</h5>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="DDD" prop="DDD">
                <el-input type="text" v-model="agencia.DDD" maxlength="2"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="Telefone" prop="Telefone">
                <el-input type="text" v-model="agencia.Telefone" maxlength="9" placeholder="Telefone"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="16">
              <el-form-item label="CEP" prop="Cep">
                <el-input v-mask="['##.###-###']" type="text" v-model="agencia.Cep" @keyup.native="carregarCep()"
                          placeholder="CEP"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="UF" prop="Uf">
                <el-input type="text" v-model="agencia.Uf" placeholder="UF" maxlength="2"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="Endereço" prop="Logradouro">
                <el-input type="text" v-model="agencia.Logradouro" placeholder="Endereço"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Cidade" prop="Cidade">
                <el-input type="text" v-model="agencia.Cidade" placeholder="Cidade" maxlength="2"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Bairro" prop="Bairro">
                <el-input type="text" v-model="agencia.Bairro" placeholder="Bairro" maxlength="2"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row>
          <div v-show="stepPosition === 2" class="form-inline margin-top-15">
            <el-row class="margin-bottom-20">
              <h5 class="subtitle">Informe seus dados bancários para recebimento</h5>
            </el-row>
            <el-form-item label="Nome do Favorecido" class="col-md-12" prop="NomeFavorecido">
              <el-input type="text" v-model="agencia.NomeCompleto" placeholder="Nome do Favorecido"></el-input>
            </el-form-item>
            <el-form-item label="Banco" class="col-md-12" prop="Banco">
              <el-select v-model="agencia.Banco" placeholder="Banco">
                <el-option v-for="item in bancos" :key="item.Id" :label="item.Codigo + ' - ' + item.Nome"
                           :value="item.Id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Agencia" class="col-md-4 mti-5" prop="Agencia">
              <el-input type="text" v-model="agencia.Agencia" placeholder="Agência"></el-input>
            </el-form-item>
            <el-form-item label="Digito" class="col-md-2 mti-5" prop="AgenciaDigito">
              <el-input type="text" v-model="agencia.AgenciaDigito"></el-input>
            </el-form-item>
            <el-form-item label="Conta" class="col-md-4 mti-5" prop="Conta">
              <el-input type="text" v-model="agencia.Conta" placeholder="Conta"></el-input>
            </el-form-item>
            <el-form-item label="Digito" class="col-md-2 mti-5" prop="ContaDigito">
              <el-input type="text" v-model="agencia.ContaDigito"></el-input>
            </el-form-item>
          </div>
          <label-erro-login :msg="msgErro"></label-erro-login>
        </el-row>
        <el-row>
          <div class="button-row" v-if="isAfiliadoTrivelo || isAfiliadoMoblix">
            <el-button v-if="stepPosition > 0" type="primary" @click="VoltarPasso()">
              <i class="el-icon-d-arrow-left"></i>
              Voltar
            </el-button>
            <el-button type="primary" v-if="stepPosition < 2" class="proximoPasso" @click="ProximoPasso()">Próximo
              <i class="el-icon-d-arrow-right"></i>
            </el-button>
          </div>
        </el-row>
        <el-row>
          <div class="margin-top-15" v-if="(!isAfiliadoTrivelo && !isAfiliadoMoblix) || stepPosition === 2">
            <el-button id="btn-criar" :loading="carregando" type="primary" class="full-width" @click="CriarAgencia()">
              <span v-if="isAfiliadoTrivelo || isAfiliadoMoblix">Crie sua conta de parceiro</span>
              <span v-else>Crie sua conta grátis</span>
              <i class="el-icon-check el-icon-right" v-if="!carregando"></i>
            </el-button>
            <div class="logIn">
              <p>Já tem uma conta Moblix?
                <router-link to="/login">Clique aqui!</router-link>
              </p>
            </div>
          </div>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import LabelErroLogin from "@/components/Login/LabelErroLogin";
import LojaService from "@/services/LojaService";
import BancoService from '../../services/BancoService'
import CepService from '../../services/CepService'
import FacebookPixelService from '../../services/FacebookPixelService'
import LoginService from '../../services/LoginService'
import imageUrlLogo from '../../assets/img/logo-moblix_azul.svg'

export default {
  name: "Cadastro",
  props: [''],
  data() {
    return {
      validationStart: false,
      imageUrl: `${imageUrlLogo}`,
      carregando: false,
      isAfiliadoTrivelo: false,
      isAfiliadoMoblix: false,
      stepPosition: 0,
      bancoService: null,
      cepService: null,
      agencia: {
        AceitoTermos: false,
        NomeEmpresa: '',
        Email: '',
        Senha: '',
        NomeCompleto: '',
        DDD: '',
        Telefone: '',
        Cep: '',
        Logradouro: '',
        Cidade: '',
        Bairro: '',
        Uf: '',
        Numero: '',
        Banco: '',
        Agencia: '',
        AgeincaDigito: '',
        Conta: '',
        ContaDigito: '',
      },
      agenciaLanding: null,
      rulesStep0: {
        AceitoTermos: [{
          required: true,
          pattern: /^[0-9a-zA-Z ]+$/,
          message: 'Você precisa ler e concordar',
          trigger: 'blur'
        }],
        NomeEmpresa: [{required: true, message: 'Informe o nome da empresa', trigger: 'blur'}, {
          required: true,
          message: 'Somente letras e números',
          trigger: 'blur'
        }],
        Email: [{required: true, message: 'Informe E-mail', trigger: 'blur'}, {
          type: 'email',
          message: 'Informe E-mail correto',
          trigger: 'blur'
        }],
        Senha: [{required: true, message: 'Informe Senha', trigger: 'blur'}],
      },
      rulesStep1: {
        DDD: [{required: true, message: 'DDD', trigger: 'blur'}],
        Telefone: [{required: true, message: 'Informe Telefone', trigger: 'blur'}],
        Cep: [{required: true, message: 'Informe CEP', trigger: 'blur'}],
        Logradouro: [{required: true, message: 'Informe Endereço', trigger: 'blur'}],
        Uf: [{required: true, message: 'UF', trigger: 'blur'}],
        Cidade: [{required: true, message: 'Informe Cidade', trigger: 'blur'}],
        Bairro: [{required: true, message: 'Informe Bairro', trigger: 'blur'}],
      },
      rulesStep2: {
        Banco: [{required: true, message: 'Informe Banco', trigger: 'blur'}],
        Agencia: [{required: true, message: 'Informe Agência', trigger: 'blur'}],
        AgenciaDigito: [{required: true, message: 'Digito', trigger: 'blur'}],
        Conta: [{required: true, message: 'Informe Conta', trigger: 'blur'}],
        ContaDigito: [{required: true, message: 'Digito', trigger: 'blur'}],
      },
      msgErro: '',
      bancos: {},
    }
  },
  components: {
    LabelErroLogin,
  },
  created() {
    this.loja = new LojaService(this)
    this.loginService = new LoginService(this)
    this.isAfiliadoTrivelo = this.getIsAfiliadoTrivelo()
    this.isAfiliadoMoblix = this.getIsAfiliadoMoblix()
    this.agencia.isAfiliadoTrivelo = this.isAfiliadoTrivelo
    this.agencia.isAfiliadoMoblix = this.isAfiliadoMoblix
    this.bancoService = new BancoService(this)
    this.bancoService.ListarCreate(this.callbackBancos)
    this.cepService = new CepService(this)
    this.fbPixel = new FacebookPixelService()
    this.CadastroLanding()
  },
  mounted() {
    this.confirmarLanding()
  },
  methods: {
    CadastroLanding() {
      if (this.$route.query.nomeAgencia) {
        const nome = decodeURIComponent(this.$route.query.nomeAgencia)
        const email = decodeURIComponent(this.$route.query.emailAgencia)
        const senha = atob(decodeURIComponent(this.$route.query.senhaAgencia))
        this.agenciaLanding = {
          NomeEmpresa: nome,
          Email: email,
          Senha: senha
        }
      }
    },
    confirmarLanding() {
      if (this.agenciaLanding !== null) {
        this.agencia.NomeEmpresa = this.agenciaLanding.NomeEmpresa;
        this.agencia.Email = this.agenciaLanding.Email;
        this.agencia.Senha = this.agenciaLanding.Senha;
        this.agencia.AceitoTermos = true;

        document.getElementById("btn-criar").click();
      }
    },
    Validar() {
      this.validationStart = true;
      this.$refs["agenciaForm"].validate((valid) => {
        this.agencia.Valid = valid;
        return false;
      });

      if (this.agencia.Valid && !this.agencia.AceitoTermos) {
        return false;
      }

      return this.agencia.Valid;
    },
    CriarAgencia() {
      if (this.Validar()) {
        this.carregando = true
        this.$emit('setMsgLoading', 'Aguarde, estamos criando sua agência online! ⚡' + this.agencia.NomeEmpresa)
        this.loja.NovaAgenciaSimplificada(this.agencia, this.callbackSuccess, this.callbackError)
      }
    },
    callbackSuccess(res) {
      this.$emit('setMsgLoading', 'Efetuando login...')
      if (res.Success) {
        this.fbPixel.Track('CompleteRegistration')
        this.gtag_report_conversion()
        this.EfetuarLogin()
      } else {
        this.msgErro = 'Não foi possível criar a agência. Verifique os campos informados.'
      }
    },
    callbackError(res) {
      this.carregando = false;
      this.$emit('setMsgLoading', null)
      if (res.Erro && res.Erro.Message)
        this.msgErro = res.Erro.Message
      else
        this.msgErro = 'Não foi possível criar a agência. Verifique os campos informados.'
    },
    EfetuarLogin() {
      let ref = this
      this.loginService.Login(this.agencia.Email, this.agencia.Senha,
          (res) => {
            if (res) {
              sessionStorage.setItem('newAgency', true)
              ref.$emit('LoginSuccess', true)
            }
          },
          (erro) => {
            ref.msgErro = 'Falha no login: ' + erro
          }
      )
    },
    ProximoPasso() {
      if (!this.Validar()) {
        return;
      }
      setTimeout(() => {
        this.$refs["agenciaForm"].clearValidate();
      }, 10);

      if (this.stepPosition < 3) {
        this.stepPosition++
      }
    },
    VoltarPasso() {
      this.$refs["agenciaForm"].clearValidate();
      if (this.stepPosition > 0) {
        this.stepPosition--
      }
    },
    callbackBancos(res) {
      this.bancos = res.Data;
    },
    carregarCep() {
      let cep = this.agencia.Cep.replace('-', '').replace('.', '');
      if (cep.length !== 8) {
        return
      }
      let that = this
      this.cepService.BuscarCep(cep, function (res) {
        that.agencia.Bairro = res.bairro
        that.agencia.Logradouro = res.logradouro
        that.agencia.Uf = res.uf
        that.agencia.Cidade = res.localidade
        that.agencia.DDD = res.ddd
      })
    },
    getRules() {
      if (this.stepPosition === 0) {
        return this.rulesStep0
      }
      if (this.stepPosition === 1) {
        return this.rulesStep1
      }
      if (this.stepPosition === 2) {
        return this.rulesStep2
      }
    },
    gtag_report_conversion() {
      if (this.$gtm.enabled()) {
        this.$gtm.trackEvent({
          event: 'criar-agencia',
        });
      }
    },
  },
}
</script>

<style scoped>
.view-container {
  width: 100vw;
  min-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: auto;
}

.card-container {
  width: 550px;
}

.subtitle {
  font-weight: bold;
  font-size: 1.1rem;
  margin: 15px;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
}

.checkbox-content {
  margin-bottom: 0 !important;
  line-height: 100% !important;
}

.proximoPasso {
  float: right;
}

.form-inline-b {
  color: black;
  font-size: 1rem;
  padding-left: 10px;
  padding-right: 10px;
}

.form-inline label {
  justify-content: left;
  color: black;
  font-size: 1rem;
  padding-left: 10px;
  padding-right: 10px;
}

.el-select {
  width: 100%;
}

.mti-5 {
  margin-top: 5px;
}

.logIn {
  display: flex;
  justify-content: center;
}

.logIn > p {
  margin-top: .5rem;
  font-size: 0.8rem;
}

.spn-erro-termos {
  color: var(--danger);
  margin-left: 10px;
  position: relative;
  top: -12px;
}

.button-row {
  justify-content: space-between;
  margin-top: 20px;
}

.margin-step {
  margin: 40px 30px 40px 30px;
}
</style>
